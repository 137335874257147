/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GameContext } from "../store/GameContext";
import "./NewView.css";
import { saveAs } from "file-saver";
import { toBlob } from "html-to-image";
import moment from "moment/moment";

const WordsTable = () => {
  const history = useHistory();
  const { room } = useContext(GameContext);
  const [isListReady, setIsListReady] = useState(false);
  const [blindWords, setBlindWords] = useState([]);
  const [hiddenWords, setHiddenWords] = useState([]);
  const [openWords, setOpenWords] = useState([]);
  const [unknownWords, setUnknownWords] = useState([]);
  const [resultStatus, setResultStatus] = useState(false);
  const elementRef = useRef();

  useEffect(() => {
    console.log(openWords);
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage =
        "Closing or leaving the page will remove you from the room!";

      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    });
    // console.log(room.onMessage)
    if (!room.onMessage) {
      history.push("/");
    }
    room.onMessage &&
      room.onMessage("to-display-table", (msg) => {
        console.log(msg);
        if (msg.value) {
          setBlindWords(msg.value.blind);
          setHiddenWords(msg.value.hidden);
          setOpenWords(msg.value.open);
          setUnknownWords(msg.value.unknown);
          setIsListReady(true);
        }
      });

    room.onMessage &&
      room.onMessage("to-restart-game", (msg) => {
        history.push(msg);
      });

    room.onMessage &&
      room.onMessage("roomDisposed", (msg) => {
        console.log(msg.message);
        history.push("/");
      });
    return () => {
      console.log("removing listeners");
      room.removeAllListeners && room.removeAllListeners();
    };
  }, []);
  const downloadJohariWindow = async () => {
    var date = new Date();
    date = moment(date).format().slice(0, 16).replace("T", "_");
    toBlob(elementRef.current).then((blob) => {
      saveAs(
        blob,
        "johari_window_" +
          room?.state?.subject +
          "_" +
          room.id +
          "_" +
          date +
          ".png"
      );
    });
  };
  return isListReady ? (
    <div className="table-wrapper">
      <h1 className="intro-title">Amazing Observers</h1>
      <button
        className="inputs-btn"
        style={{ display: "block", marginLeft: "auto", marginRight: "40px" }}
        onClick={downloadJohariWindow}
      >
        Download
      </button>
      <br />
      <div ref={elementRef}>
        <center>
          <h4 style={{ color: "white" }}>
            {room?.state?.subject}'s Johari Window
          </h4>
        </center>
        <br />
        <ul className="table">
          <li className="table-desc">Known to Subject</li>
          <li className="table-desc">Unknown to Subject</li>
          <li className="table-desc inverted">Known to Observers</li>
          <li className="table-item">
            <p className="category-title">Open</p>
            <div className="word-block">
              {openWords.map((value) => {
                return (
                  <div key={value.word} className="word-wrapper">
                    <p
                      style={{
                        color: `${value.color}`,
                        fontSize: `${value.size}`,
                      }}
                    >
                      {value.word}
                    </p>
                    <p className="count-votes">{value.count}</p>
                  </div>
                );
              })}
            </div>
          </li>
          <li className="table-item">
            <p className="category-title">Blind</p>
            <div className="word-block">
              {blindWords.map((value) => {
                return (
                  <div key={value.word} className="word-wrapper">
                    <p
                      style={{
                        color: `${value.color}`,
                        fontSize: `${value.size}`,
                      }}
                    >
                      {value.word}
                    </p>
                    <p className="count-votes">{value.count}</p>
                  </div>
                );
              })}
            </div>
          </li>
          <li className="table-desc inverted">Unknown to Observers</li>
          <li className="table-item">
            <p className="category-title">Hidden</p>
            <div className="word-block">
              {hiddenWords.map((value) => {
                return (
                  <div key={value.word} className="word-wrapper">
                    <p
                      style={{
                        color: `${value.color}`,
                        fontSize: `${value.size}`,
                      }}
                    >
                      {value.word}
                    </p>
                    <p className="count-votes">{value.count}</p>
                  </div>
                );
              })}
            </div>
          </li>
          <li className="table-item">
            <p className="category-title">Unknown</p>
            <div className="word-block">
              {unknownWords.map((value) => {
                return (
                  <div key={value.word} className="word-wrapper">
                    <p
                      style={{
                        color: `${value.color}`,
                        fontSize: `${value.size}`,
                      }}
                    >
                      {value.word}
                    </p>
                    <p className="count-votes">{value.count}</p>
                  </div>
                );
              })}
            </div>
          </li>
        </ul>
      </div>
      {room.sessionId === room?.state?.host ? (
        <>
          <button
            onClick={() => room.send && room.send("restart-game")}
            className="inputs-btn"
          >
            Play Again
          </button>
          <br />
          <button
            onClick={() => {
              room.send && room.send("end");
              history.push("/admin");
            }}
            className="inputs-btn"
          >
            New Game
          </button>
        </>
      ) : null}
    </div>
  ) : (
    <div className={room.sessionId !== room?.state?.host?"game-screen":"table-wrapper"}>
      <h1 className="intro-title">Amazing Observers</h1>
      {room.sessionId !== room?.state?.host && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <br />
          <h4 className="list-title">
            Answers Received. Please wait for the host to display the results.
          </h4>
        </div>
      )}
      {resultStatus &&
        <p className="side-content">
          Loading Results...
        </p>
      }
      {(room.sessionId === room?.state?.host && !resultStatus) && (
        <p className="side-content">
          Everyone has submitted. <br />
          Please click 'Results' to display the Johari Window.
        </p>
      )}
      <br />
      <br />
      {(room.sessionId === room?.state?.host && !resultStatus) && (
        <button
          onClick={() => {
            setResultStatus(true)
            room.send && room.send("move-to-table", "");
          }}
          className={`inputs-btn`}
        >
          Results
        </button>
      )}
    </div>
  );
};

export default WordsTable;
