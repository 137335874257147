import "./Header.css";
import logo from "../../images/logo.svg";
import useAuth from "../../app/hooks/useAuth";
import { useHistory } from "react-router-dom";
import { GameContext } from "../../store/GameContext";
import { useContext } from "react";

const Header = () => {
  const history = useHistory();
  const {client, setClient, room, setRoom} = useContext(GameContext)
  const { user, isAuthenticated, logout } = useAuth();
  const handleLogout = () => {
    room && room.send && room.send("end");
    logout();
    history.push("/");
  };
  return (
    <header className="header">
      <img
        className="logo"
        src={logo}
        alt="logo"
        onClick={() => {
          history.push("/");
        }}
      />
      {(window.location.pathname == "/admin" || isAuthenticated) && (
        <button
          onClick={() => {
            isAuthenticated ? handleLogout() : history.push("/admin");
          }}
          className={
            !isAuthenticated
              ? "inputs-btn-header"
              : "inputs-btn-header-secondary"
          }
        >
          {!isAuthenticated ? "Sign In" : "Log Out"}
        </button>
      )}
    </header>
  );
};

export default Header;
