import React, { useContext, useEffect, useState } from "react";
import { GameContext } from "../store/GameContext";
import * as Colyseus from "colyseus.js";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import AOLogo from "../images/AO_Logo.svg";

// import "../components/game.css";
import "./NewView.css";

function Join() {
  const API_URL = process.env.REACT_APP_API_URL
  // const { roomCode } = useParams();
  const { client, setClient, setRoom } = useContext(GameContext);
  const history = useHistory();
  const [roomCodeInput, setRoomCodeInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [isJoinDisabled, setIsJoinDisabled] = useState(false);
  const gameData = JSON.parse(localStorage.getItem("gameData"));
  useEffect(() => {
    let isMounted = true;

    const colyseusClient = createClient();

    if (gameData && colyseusClient) {
      colyseusClient
        .reconnect(gameData.roomId, gameData.sessionId)
        .then((room) => {
          console.log("joined successfully", room);
          if (isMounted) handleRoom(room);
        })
        .catch((e) => {
          console.error("join error", e);
          if (e?.message?.includes("session expired")) {
            toast.error("session expired / game opened somewhere else");
          } // clear if not able to join
          else {
            toast.error(e.message || "failed to join previous game");
            localStorage.clear();
          }
          history.push("/");
        });
    }
    const listener = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", listener);
    return () => {
      window.removeEventListener("beforeunload", listener);
      return isMounted = false
    };
  }, []);

  function createClient() {
    // const colyseusClient = new Colyseus.Client(
    //   "ws://fathomless-waters-17589.herokuapp.com"
    // );
    const colyseusClient = new Colyseus.Client(API_URL);
    setClient(colyseusClient);
    console.log("client created", colyseusClient, client);

    const gameData = JSON.parse(localStorage.getItem("gameData"));
    if (gameData) {
      colyseusClient
        .reconnect(gameData.roomId, gameData.sessionId)
        .then((room) => {
          console.log("joined successfully", room);
          handleRoom(room);
        })
        .catch((e) => {
          console.error("join error", e);
          if (e?.message?.includes("session expired")) {
            toast.error('looks like you"ve opened the game somewhere else');
            toast("close that to join here ");
          } // clear if not able to join
          else {
            toast.error(e.message || "failed to join previous game");
            localStorage.clear();
          }
        });
    }
  }
  function handleRoom(room) {
    setRoom(room);
    const gameData = {
      sessionId: room.sessionId,
      roomId: room.id,
    };
    localStorage.setItem("gameData", JSON.stringify(gameData));
    console.log(room.id, "room joined");
    history.push(`/players`);
  }

  function handleJoin(e) {
    e.preventDefault();
    if (nameInput && roomCodeInput) {
      setIsJoinDisabled(true);
      client
        .joinById(roomCodeInput, {
          username: nameInput,
        })
        .then(handleRoom)
        .catch((e) => {
          console.log("JOIN ERROR", e);
          toast.error(`${e.message || "failed to join room"}`);
        })
        .finally(() => {
          setIsJoinDisabled(false);
        });
    }
  }

  return (
    <section className="join">
      <img className="game-logo" src={AOLogo} alt="Logo" />
      <div className="intro margin-s">
        <h1 className="intro-title">Amazing Observers</h1>
        <p className="intro-content">
          Are you good at observing people? Then, it's time to prove,your skills
          to claim the 'Amazing Observer' title.
        </p>
      </div>
      <div className="input-container">
        <h2>Join Room</h2>
        <div className="inputs-group inputs-join">
          <label htmlFor="name">Enter Your Name</label>
          <input
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
            placeholder="Player Name"
          />
        </div>
        <div className="inputs-group inputs-join" id="room-code-input">
          <label htmlFor="room-code">Enter Room Code</label>
          <input
            type="text"
            value={roomCodeInput}
            onChange={(e) => setRoomCodeInput(e.target.value)}
            placeholder="Enter Code to Join"
          />
        </div>
        <button
          disabled={isJoinDisabled}
          onClick={handleJoin}
          className="inputs-btn"
        >
          Join Room
          {isJoinDisabled && (
            <>
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </>
          )}
        </button>
      </div>
    </section>
  );
}

export default Join;
