import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GameContext } from "../store/GameContext";
import "../views/NewView.css";
import * as Colyseus from "colyseus.js";
import toast from "react-hot-toast";

const Game = ({ subject }) => {
  const API_URL = process.env.REACT_APP_API_URL
  const gameData = JSON.parse(localStorage.getItem("gameData"));
  const history = useHistory();
  const { room, client, setClient, setRoom } = useContext(GameContext);
  const [chosen, setChosen] = useState(
    room?.state?.adjectives.map((value) => false)
  );
  const [limitWords, setLimitWords] = useState("nolimit");
  const [wordCount, setWordCount] = useState(0);
  const [wordIndex, setWordIndex] = useState([]);
  const [playersCount, setPlayersCount] = useState(0);
  const [observers, setObservers] = useState([]);
  const [submitChoice, setSubmitChoice] = useState("not-submitted");
  const [time, setTime] = useState(120);
  const [adjectives, setAdjectives] = useState([]);
  const [timeDisplay, setTimeDisplay] = useState({
    minutes: "00",
    seconds: "00",
  });

  function createClient() {
    const colyseusClient = new Colyseus.Client(API_URL);
    setClient(colyseusClient);
    console.log("client created", colyseusClient, client);
    return colyseusClient;
  }

  function handleRoom(room) {
    setRoom(room);
    // history.push(`/game`);
    const gameData = {
      sessionId: room.sessionId,
      roomId: room.id,
    };
    localStorage.setItem("gameData", JSON.stringify(gameData));
    console.log(room.id, "room joined");
  }

  useEffect(async () => {
    const colyseusClient = createClient();
    let isMounted = true;
    console.log("Inside useEffect");
    console.log(room);
    // alert(gameData.sessionId)
    if (gameData && !room.onMessage) {
      await colyseusClient
        .reconnect(gameData.roomId, gameData.sessionId)
        .then((room) => {
          console.log("joined successfully", room);
          if (isMounted) handleRoom(room);
        })
        .catch((e) => {
          console.error("join error", e);
          history.push("/");
          if (e?.message?.includes("session expired")) {
            toast.error(
              "Looks like you've opened the game somewhere else.    \n close that to join here "
            );
            // toast("");
          } // clear if not able to join
          else {
            toast.error(e.message || "failed to join previous game");
            localStorage.clear();
          }
        });
    }
    setTime(room.state?.timeInterval);
    if (playersCount === 0) {
      console.log(playersCount);
      room.send && room.send("no-of-players", "");
    }

    room &&
      room.onMessage &&
      room.onMessage("set-no-of-players", (msg) => {
        console.log(msg);
        setPlayersCount(msg.noOfPlayers);
        setObservers(msg.observers);
        // room.send && room.send("get-observers", "");
      });

    room &&
      room.onMessage &&
      room.onMessage("set-table-route", (msg) => {
        history.push(msg);
      });

    room &&
      room.onMessage &&
      room.onMessage("roomDisposed", (msg) => {
        console.log(msg.message);
        history.push("/");
      });

    room &&
      room.onMessage &&
      room.onMessage("no-of-responses", (msg) => {
        console.log(
          `Value of noOfPlayers ${playersCount}, Value of msg ${msg}`
        );
        console.log(msg.player);
        setPlayersCount(msg.responses);
        setObservers((prevValue) =>
          prevValue.map((observer) => {
            if (msg.player.id === observer.id) {
              return { ...msg.player, status: "yes" };
            } else {
              return observer;
            }
          })
        );
        if (msg.responses === 0) {
          setTimeout(() => {
            history.push("/table");
          }, 1000);
        }
      });
    room && room?.state?.adjectives && setAdjectives(shuffle(room?.state?.adjectives));
    const listener = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", listener);
    return () => {
      room.send("kick", room.sessionId);
      window.removeEventListener("beforeunload", listener);
      console.log("removing listeners");
      room.removeAllListeners();
    };
  }, []);
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }
  useEffect(() => {
    const intervalID = setInterval(() => {
      let min = `${Math.floor(time / 60)}`;
      if (min < 10) {
        min = "0" + min;
      }
      let sec = `${time % 60}`;
      if (sec < 10) {
        sec = "0" + sec;
      }
      setTimeDisplay({
        minutes: min,
        seconds: sec,
      });
      console.log(time);
      setTime((prevTime) => prevTime - 1);
    }, 1002);
    if (time < 0 || submitChoice === "submitted") {
      if (
        submitChoice === "not-submitted" &&
        room.sessionId !== room.state.host
      ) {
        displayTable();
      }
      clearInterval(intervalID);
    }
    return () => clearInterval(intervalID);
  }, [time]);

  const handleChoose = (index, e) => {
    // alert(wordIndex.length)
    // alert(wordCount)
    // alert(e.target.checked)
    console.log(wordIndex);
    if (e.target.checked) {
      if (wordIndex.length < room?.state?.maxWords) {
        if (wordCount + 1 === room?.state?.maxWords) {
          setLimitWords("limit");
        }
        setWordCount(wordIndex.length + 1);
        setWordIndex((prevValue) =>
          prevValue.concat(room?.state?.adjectives[index])
        );
        let modifiedAdj = [...chosen];
        modifiedAdj[index] = true;
        setChosen(modifiedAdj);
      }
    } else {
      if (wordIndex.length - 1 < room?.state?.maxWords) {
        setLimitWords("nolimit");
      }
      setWordCount(wordIndex.length - 1);
      setWordIndex(
        wordIndex.filter((val) => val != room?.state?.adjectives[index])
      );
      let modifiedAdj = [...chosen];
      modifiedAdj[index] = false;
      setChosen(modifiedAdj);
    }
  };

  function displayTable() {
    if (wordCount === room?.state?.maxWords) {
      room.send && room.send("choose-adjective", { value: wordIndex });
      console.log(wordIndex);
      room.send && room.send("display-table", playersCount);
      setSubmitChoice("submitted");
    } else {
      // toast.error("Please Choose " + room?.state?.maxWords + " Adjectives to Submit!")
      alert(
        "Please Choose " + room?.state?.maxWords + " Adjectives to Submit!"
      );
    }
    // history.push("/table");
  }
  return (
    <div className="game-screen">
      <h1 className="intro-title">Amazing Observers</h1>

      {submitChoice != "submitted" && (
        <div className="game-content">
          <div className="adjectives">
            <h4 className="group-title" style={{ fontSize: "20px" }}>
              Hi {room?.state?.players[room?.sessionId]["username"]}
            </h4>
            <p className="choose-words-desc">
              Choose{" "}
              {room?.state?.maxWords ? room?.state?.maxWords : "Infinite"}{" "}
              adjectives defining{" "}
              <span>
                {room?.state?.players[room?.sessionId]["username"] ==
                room?.state?.subject
                  ? "You"
                  : room?.state?.subject}
              </span>
            </p>
            <h2 className="group-title">Adjective List</h2>
            <ul className="list-group">
              {adjectives.map((adjective, index) => {
                return (
                  <div key={index}>
                    <label
                      htmlFor={`wordChoice${index}`}
                      className={`list-group-item ${
                        chosen[index] ? "nolimit" : limitWords
                      } ${chosen[index] ? "active" : ""} ${
                        room?.state?.host === room.sessionId ? "host" : ""
                      }`}
                    >
                      {adjective}
                      <span className="player-color-block"></span>
                    </label>
                    <input
                      className="word-choice-input"
                      type="checkbox"
                      disabled={
                        !chosen[index] &&
                        wordIndex.length === room?.state?.maxWords
                          ? true
                          : false
                      }
                      id={`wordChoice${index}`}
                      onClick={(e) => {
                        // console.log(index);
                        handleChoose(index, e);
                      }}
                    />
                  </div>
                );
              })}
            </ul>
            {/* <div className="category-list subjects subject-details">
            <p>Subject : </p>
            <p className="player">{room?.state?.subject}</p>
          </div> */}
          </div>
          <div className="game-details">
            <div className="details">
              <div className="info timer">
                <p>
                  {timeDisplay.minutes}:{timeDisplay.seconds}
                </p>
              </div>
              {room.state?.host && room.state?.host === room.sessionId ? (
                <div className="info players-left">
                  <p>Answers Awaited</p>
                  <p>{playersCount}</p>
                </div>
              ) : null}
            </div>
            <div className="category-list subjects subject-details">
              <p>Subject : </p>
              <p className="player">{room?.state?.subject}</p>
            </div>
            {room.state?.host && room.state?.host === room.sessionId ? (
              <ul className="observers-list">
                <li className="observers-list-title">Players</li>
                {observers.map((observer, index) => {
                  return (
                    <li
                      key={Math.random() * 10000}
                      className={`observer ${observer.status}`}
                    >
                      <p className="observer-name">{observer.name}</p>
                      <span className={`status`}></span>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </div>
      )}
      {submitChoice == "submitted" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <br />
          <h4 className="list-title">
            Answers Received. Please wait for the host to display the results.
          </h4>
        </div>
      )}
      {room.sessionId === room?.state?.host ? (
        <button
          onClick={() => room.send && room.send("move-to-table", "")}
          className={`inputs-btn`}
        >
          Results
        </button>
      ) : (
        <>
          {submitChoice != "submitted" && (
            <button
              onClick={displayTable}
              className={`inputs-btn ${submitChoice}`}
            >
              Submit
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Game;
