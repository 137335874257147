import React, { useState, useContext, useEffect } from "react";
import { FaTimes, FaAngleUp, FaAngleDown } from "react-icons/fa";
import toast from "react-hot-toast";
import { GameContext } from "../store/GameContext";
import "../components/game.css";
import { useHistory } from "react-router-dom";
import "./NewView.css";
// import "./GameSetup.css";

// Game settings will be done here
const GameView = () => {
  const history = useHistory();
  const { room, setRoom } = useContext(GameContext);
  // const [players, setPlayers] = useState({});
  // const [isGameStarted, setIsGameStarted] = useState(false);
  const [adjective, setAdjective] = useState("");
  const [host, setHost] = useState(null);
  const [subjectClick, setSubjectClick] = useState(true);
  const [maxAdjectives, setMaxAdjectives] = useState(5);
  const [adjectiveList, setAdjectiveList] = useState([]);
  const [timeLimit, setTimeLimit] = useState(120);

  function copyRoomCode() {
    const input = document.createElement("input");
    input.value = room.id;
    document.body.append(input);
    input.select();
    document.execCommand("copy");
    input.remove();
    console.log("copied");
    toast("code copied");
  }

  function handleGameStart() {
    console.log("Starting Game...");
    room.send && room.send("start", { value: true });
    history.push("/players");
  }
  // function handleLeave() {
  //   history.push("/join");
  //   room.leave(true);
  //   toast("good bye ... 👋");
  //   localStorage.clear();
  //   setRoom({ id: "" });
  // }

  useEffect(() => {
    // setAdjectiveList(room?.state?.adjectives.map(adjective => adjective));
    console.log(room);

    room.state.adjectives.onAdd = (word, index) => {
      console.log(word, index);
      setAdjectiveList(prevValue => [...prevValue, word]);
      // word.forEach(item => console.log(item));
      setAdjective(...adjective, word);
      setAdjective("");
    };

    room.state.adjectives.onRemove = (word, index) => {
      console.log(word, index);
      setAdjectiveList(prevValue =>
        prevValue.filter((item, itemIndex) => item !== word)
      );
    };

    const hostChange = room?.state?.listen("host", currentValue => {
      console.log(room.sessionId, host, currentValue);
      setHost(currentValue);
    });

    const maxWordsChange = room?.state?.listen("maxWords", currentValue => {
      console.log(currentValue);
      setMaxAdjectives(currentValue);
    });

    room.onError((code, message) => {
      console.log("oops, error ocurred : code ", code);
      console.log(message);
      toast.error(message || "500 internal server error");
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubject() {
    room.send && room.send("add-subject", { value: true });
    setSubjectClick(!subjectClick);
  }

  function sendMaxAdjectives() {
    room.send && room.send("max-adjectives", { value: maxAdjectives });
    setMaxAdjectives(0);
  }

  function increaseMaxAdjectives() {
    room.send && room.send("max-adjectives", maxAdjectives + 1);
    setMaxAdjectives(prevValue => prevValue + 1);
  }

  function decreaseMaxAdjectives() {
    room.send && room.send("max-adjectives", maxAdjectives - 1);
    setMaxAdjectives(prevValue => prevValue - 1);
  }

  function changeTimeLimit(e) {
    room.send && room.send("time-limit", parseInt(e.target.value));
    setTimeLimit(parseInt(e.target.value));
  }

  function increaseTimeLimit() {
    room.send && room.send("time-limit", timeLimit + 1);
    setTimeLimit(prevValue => prevValue + 1);
  }

  function decreaseTimeLimit() {
    room.send && room.send("time-limit", timeLimit - 1);
    setTimeLimit(prevValue => prevValue - 1);
  }

  function handleAdd() {
    room.send && room.send("add-adjective", { value: adjective });
    setAdjective("");
  }

  function removeAdjectives(index) {
    room.send && room.send("remove-adjective", index);
  }

  return (
    <>
      <div className="adj-container">
        <h1 className="intro-title">Amazing Observers</h1>
        {room?.sessionId === host && (
          <div className="d-flex justify-content-around adj-wrapper">
            <div className="input-box">
              <h2 className="box-title">Game Settings</h2>
              <p onClick={copyRoomCode} className="room-code me-2">
                Room Code :{" "}
                <strong className="highlight-inputs">{room.id} </strong>
              </p>
              <div className="inputs">
                <div>
                  <div>
                    <label>
                      Adjectives to be chosen
                      {/* {room?.state?.maxWords ? room?.state?.maxWords : "Infinite"} */}
                    </label>
                    {/*  */}
                    <div>
                      <input
                        type="text"
                        value={maxAdjectives}
                        onChange={e => {
                          setMaxAdjectives(Number(e.target.value));
                        }}
                      />
                      <button
                        onClick={increaseMaxAdjectives}
                        className="inc-btn"
                      >
                        <FaAngleUp />
                      </button>
                      <button
                        onClick={decreaseMaxAdjectives}
                        className="dec-btn"
                      >
                        <FaAngleDown />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <label>
                      Time Limit (in seconds)
                    </label>
                    {/*  */}
                    <div className="timer-input">
                      <input
                        type="text"
                        inputMode="numeric"
                        value={timeLimit}
                        onChange={changeTimeLimit}
                      />
                      <button onClick={increaseTimeLimit} className="inc-btn">
                        <FaAngleUp />
                      </button>
                      <button onClick={decreaseTimeLimit} className="dec-btn">
                        <FaAngleDown />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div>
                    <input
                      type="text"
                      value={adjective}
                      placeholder="Add New Word Here"
                      onChange={e => {
                        setAdjective(e.target.value);
                      }}
                      className="add-word-input"
                    />
                    <button
                      className="word-btn"
                      onClick={() => {
                        handleAdd();
                      }}
                    >
                      + Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="adjectives">
              <h2 className="group-title">Adjective List</h2>
              <ul className="list-group">
                {adjectiveList.map((adjective, index) => {
                  return (
                    <li key={index} className={`list-group-item`}>
                      {adjective}
                      <button
                        onClick={() => removeAdjectives(index)}
                        className="close-btn"
                      >
                        <FaTimes />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <button
          className="inputs-btn"
          onClick={() => {
            handleGameStart();
          }}
        >
          View Players
        </button>
      </div>
    </>
  );
};

export default GameView;
