import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GameProvider } from "./store/GameContext";
import { Toaster } from "react-hot-toast";
import Header from "./components/Header/Header";
import Home from "./views/Home";
import GameView from "./views/GameView";
import Join from "./views/Join";
import GuardedRoute from "./views/GuardedRoute";
import WordsTable from "./views/WordsTable";
import Players from "./views/Players";
import Game from "./components/Game";
import "./App.css";
import useAuth from "./app/hooks/useAuth";
import LoginPage from "./views/LoginPage";
import { AuthProvider } from "./app/contexts/AuthContext";
import AuthGuard from "./app/auth/AuthGuard";

function App() {
  return (
    <>
      {/* <img src={Logo} alt="logo" /> */}
      <Router>
        <GameProvider>
          <AuthProvider>
            <Header />
            <Switch>
              <Route exact path="/" component={Join} />
              <Route exact path="/admin" component={AuthGuard} />
              <Route path="/join/:roomCode?" component={Join} />
              <Route path="/table" component={WordsTable} />
              <Route path="/players" component={Players} />
              <Route path="/game" component={Game} />
              <GuardedRoute path="/gamesetup" component={GameView} />
            </Switch>
          </AuthProvider>
        </GameProvider>
      </Router>
      <Toaster position="bottom-right" />
    </>
  );
}

export default App;
