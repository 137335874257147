import React from 'react';
import LoginPage from '../../views/LoginPage';
import useAuth from '../hooks/useAuth';
import Home from '../../views/Home';

const AuthGuard = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Home /> : <LoginPage />;
};

export default AuthGuard;