import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GameContext } from "../store/GameContext";
import * as Colyseus from "colyseus.js";
import toast from "react-hot-toast";
import AOLogo from "../images/AO_Logo.svg";
import "../components/game.css";
import "./Views.css";
import { firebaseConfig } from "../config";
import firebase from "firebase/compat/app";
import useAuth from "../app/hooks/useAuth";

function Home() {
  const API_URL = process.env.REACT_APP_API_URL
  const { client, setClient, setRoom } = useContext(GameContext);
  const history = useHistory();
  const [nameInput, setNameInput] = useState("");
  const [adjectivesList, setAdjectivesList] = useState([]);
  const [adjectiveId, setAdjectiveId] = useState("");
  // const [maxPlayersInput, setMaxPlayersInput] = useState(2);
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);
  const gameData = JSON.parse(localStorage.getItem("gameData"));
  const {user} = useAuth()

  useEffect(() => {
    let isMounted = true;

    const colyseusClient = createClient();

    if(firebase.apps.length){
      var db = firebase.firestore();
      db.collection("adjectives")
        .get()
        .then((res) => {
          var tempAdjectivesList = [];
          res.docs.forEach((e) => {
            tempAdjectivesList.push({ id: e.id, name: e.data().name });
          });
          setAdjectivesList(tempAdjectivesList);
        });
    }

    if (gameData) {
      colyseusClient
        .reconnect(gameData.roomId, gameData.sessionId)
        .then((room) => {
          console.log("joined successfully", room);
          if (isMounted) handleRoom(room);
        })
        .catch((e) => {
          console.error("join error", e);
          if (e?.message?.includes("session expired")) {
            toast.error(
              "Looks like you've opened the game somewhere else.    \n close that to join here "
            );
            // toast("");
          } // clear if not able to join
          else {
            toast.error(e.message || "failed to join previous game");
            localStorage.clear();
          }
        });
    }

    return () => (isMounted = false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function createClient() {
    const colyseusClient = new Colyseus.Client(API_URL);
    setClient(colyseusClient);
    console.log("client created", colyseusClient, client);
    return colyseusClient;
  }
  function handleRoom(room) {
    setRoom(room);
    history.push(`/gamesetup`);
    const gameData = {
      sessionId: room.sessionId,
      roomId: room.id,
    };
    localStorage.setItem("gameData", JSON.stringify(gameData));
    console.log(room.id, "room joined");
  }

  function handleCreate(e) {
    e.preventDefault();
    if (nameInput !== "") {
      setIsCreateDisabled(true);
      client
        .create("regular", { username: nameInput, adjectiveId: adjectiveId, uid:user.id })
        .then(handleRoom)
        .catch((e) => {
          console.log("JOIN ERROR from create", e);
          toast.error(`${e.message || "failed to create room"}`);
        })
        .finally(() => {
          setIsCreateDisabled(false);
        });
    }
  }
  return (
    <section className="join">
      <img className="game-logo" src={AOLogo} alt="Logo" />
      <div className="intro">
        <h1 className="intro-title">Amazing Observers</h1>
        <p className="intro-content">
          Are you good at observing people? Then, it's time to prove,your skills
          to claim the 'Amazing Observer' title.
        </p>
      </div>
      <div className="input-container">
        <h2>Create Room</h2>
        <div className="inputs-group">
          <label htmlFor="name">Enter Your Name</label>
          <input
            id="name"
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
            placeholder="Host Name"
          />
        </div>
        <div className="inputs-group">
          <label htmlFor="adjectivesName">Select Group of Adjectives</label>
          <select
            id="adjectivesName"
            value={adjectiveId}
            onChange={(e) => setAdjectiveId(e.target.value)}
            placeholder="Select Adjectives Group"
            style={{color: adjectiveId==""?"grey":"white"}}
          >
            <option value={""} disabled style={{color:'white'}}>Select Adjectives Group</option>
            {adjectivesList.map((item, index) => (
              <option value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
        <button
          id="firstBtn"
          disabled={isCreateDisabled}
          onClick={handleCreate}
          className="inputs-btn"
        >
          Create Room
          {isCreateDisabled && (
            <>
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </>
          )}
        </button>
      </div>
    </section>
  );
}

export default Home;
