import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { GameContext } from "../store/GameContext";
import * as Colyseus from "colyseus.js";
import "./NewView.css";

const Players = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const gameData = JSON.parse(localStorage.getItem("gameData"));
  const { room, client, setClient, setRoom } = useContext(GameContext);
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [players, setPlayers] = useState({});
  const [observers, setObservers] = useState([]);
  const [subjectStatus, setSubjectStatus] = useState(false);
  const [subject, setSubject] = useState("unset");

  function createClient() {
    const colyseusClient = new Colyseus.Client(API_URL);
    setClient(colyseusClient);
    console.log("client created", colyseusClient, client);
    return colyseusClient;
  }

  function handleRoom(room) {
    setRoom(room);
    history.push(`/gamesetup`);
    const gameData = {
      sessionId: room.sessionId,
      roomId: room.id,
    };
    localStorage.setItem("gameData", JSON.stringify(gameData));
    console.log(room.id, "room joined");
  }

  // const gameData = JSON.parse(localStorage.getItem("gameData"));
  useEffect(async () => {
    const colyseusClient = createClient();
    let isMounted = true;
    console.log(room?.state?.host);
    if (room.sessionId == null) {
      history.push("/");
    }

    if (gameData && !room.onMessage) {
      await colyseusClient
        .reconnect(gameData.roomId, gameData.sessionId)
        .then((room) => {
          console.log("joined successfully", room);
          if (isMounted) handleRoom(room);
        })
        .catch((e) => {
          console.error("join error", e);
          if (e?.message?.includes("session expired")) {
            toast.error(
              "Looks like you've opened the game somewhere else.    \n close that to join here "
            );
            // toast("");
          } // clear if not able to join
          else {
            toast.error(e.message || "failed to join previous game");
            localStorage.clear();
          }
        });
    }
    // console.log(gameData);
    // console.log(room.id);
    if (room.send && room.send) {
      console.log("Inside if");
      room.send && room.send("obtain-players");

      room.onMessage &&
        room.onMessage("to-obtain-players", (msg) => {
          console.log(msg);
          let hostSubject = {};
          setPlayers(msg);
          setObservers(
            Object.entries(msg).map((entry) => {
              return {
                key: entry[0],
                name: entry[1].username,
                observer: true,
              };
            })
          );
        });

      room.onMessage &&
        room.onMessage("to-obtain-observers", (msg) => {
          console.log(msg);
          setObservers(msg);
        });

      room.onMessage &&
        room.onMessage("to-start-game", (msg) => {
          history.push(msg);
        });

      room.state.players.onAdd = (player, sessionId) => {
        console.log(player);
        if (sessionId === room.sessionId) {
          // name of user
          setUsername(player.username);
        }
        setPlayers((prevPlayers) => ({
          ...prevPlayers,
          [sessionId]: {
            username: player.username,
          },
        }));

        player.onChange = (changes) => {
          console.log(changes);
          changes.forEach((change) => {
            setPlayers((prevPlayers) => {
              console.log("Inside players change", prevPlayers);
              const newPlayersState = { ...prevPlayers };
              newPlayersState[sessionId][change.field] = change.value;
              return newPlayersState;
            });
          });
        };
      };

      room.state.players.onRemove = (player, sessionId) => {
        console.log("player left", player, sessionId);
        toast(`${player?.username} left`);
        const newPlayers = { ...players };
        delete newPlayers[sessionId];

        setPlayers(newPlayers);
        setObservers(
          Object.entries(newPlayers).map((entry) => {
            return {
              key: entry[0],
              name: entry[1].username,
              observer: true,
            };
          })
        );
        // player.triggerAll()
      };
    }
    return () => {
      console.log("removing listeners");
      room.removeAllListeners && room.removeAllListeners();
    };
  }, [room]);

  function copyRoomCode() {
    const input = document.createElement("input");
    input.value = room.id;
    document.body.append(input);
    input.select();
    document.execCommand("copy");
    input.remove();
    console.log("copied");
    toast("code copied");
  }

  const modifyPlayerValue = (value, index) => {
    const player = observers[index];
    let modifyPlayers = [...observers].filter(
      (user, userIndex) => userIndex !== index
    );
    modifyPlayers = [...modifyPlayers, { ...player, observer: value }];
    setObservers(modifyPlayers);
    room.send && room.send("obtain-observers", modifyPlayers);
  };

  const addToSubject = (index) => {
    // console.log(observers[index]);
    if (subject === "unset") {
      setSubject("set");
      modifyPlayerValue(false, index);
      room.send &&
        room.send("add-subject", {
          key: observers[index].key,
          name: observers[index].name,
        });
      setSubjectStatus(true);
    }
  };

  const addToObserver = (index) => {
    modifyPlayerValue(true, index);
    room.send && room.send("add-subject", "");
    setSubject("unset");
    setSubjectStatus(false);
  };

  const startGame = () => {
    room.send && room.send && room.send && room.send("start-game", "/game");
  };
  const cancelGame = () => {
    room.send("end");
    history.push("/admin");
  };
  // console.log(observers);
  return (
    <div className="d-flex justify-content-around adj-wrapper">
      {/* <div className="input-box">
        <p onClick={copyRoomCode} className="room-code me-2">
          Room Code :{" "}
          <strong className="highlight-inputs">{room.id} </strong>
        </p>
      </div> */}
      <div className="player-details">
        <h1 className="intro-title">Amazing Observers</h1>
        <div className="no-of-players">
          <p>{observers.length}</p>
          <p>Players Joined</p>
        </div>
        <div className="category-list">
          <h2 className="list-title">Observers</h2>
          <ul>
            {observers.map((player, index) => {
              if (player.observer) {
                if (room.sessionId === room?.state?.host) {
                  return (
                    <li
                      key={player.key}
                      // style={{ cursor: `pointer` }}
                      className={`player-${subject}`}
                      onClick={() => addToSubject(index)}
                    >
                      {`${player.name}`}
                    </li>
                  );
                } else {
                  return (
                    <li key={player.key} className={`player`}>
                      {`${player.name}`}
                    </li>
                  );
                }
              } else {
                return null;
              }
            })}
          </ul>
        </div>
        <div className="category-list subjects">
          <h2 className="list-title">
            Subject{" "}
            {room.sessionId === room?.state?.host && !subjectStatus && observers.length>0 && (
              <button
                className="inputs-btn-warning"
                onClick={() => {
                  const randomIndex = Math.floor(
                    Math.random() * observers.length
                  );
                  addToSubject(randomIndex);
                }}
              >
                Random
              </button>
            )}
          </h2>
          <ul>
            {observers?.map((player, index) => {
              if (!player.observer) {
                if (room.sessionId === room?.state?.host) {
                  return (
                    <li
                      key={player.key}
                      style={{ cursor: `pointer` }}
                      className={`player-${subject}`}
                      onClick={() => addToObserver(index)}
                    >
                      {`${player.name}`}
                    </li>
                  );
                } else {
                  return (
                    <li key={player.key} className={`player`}>
                      {`${player.name}`}
                    </li>
                  );
                }
              } else {
                return null;
              }
            })}
          </ul>
        </div>
        {room.sessionId === room?.state?.host ? (
          <>
            <button className="inputs-btn" onClick={startGame}>
              Start Game
            </button>
            {/* <br /> */}
            <button className="inputs-btn-danger" onClick={cancelGame}>
              Cancel
            </button>
          </>
        ) : null}

        {room.sessionId !== room?.state?.host && (
          <button
            onClick={() => {
              room.send("kick", room.sessionId);
              history.push("/");
            }}
            className={`inputs-btn`}
          >
            Leave
          </button>
        )}
      </div>
    </div>
  );
};

export default Players;
