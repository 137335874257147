import React, { useContext, useEffect, useState } from "react";
import { GameContext } from "../store/GameContext";
import * as Colyseus from "colyseus.js";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import AOLogo from "../images/AO_Logo.svg";
import firebase from "firebase/compat";
import "./NewView.css";
import useAuth from "../app/hooks/useAuth";
import { Box, styled, useTheme } from "@mui/material";
import clsx from "clsx";

function LoginPage() {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const { signInWithEmailAndPassword, user, isAuthenticated } = useAuth();

  const StyledBox = styled(Box)(({ theme, textTransformStyle, ellipsis }) => ({
    textTransform: textTransformStyle || "none",
    whiteSpace: ellipsis ? "nowrap" : "normal",
    overflow: ellipsis ? "hidden" : "",
    textOverflow: ellipsis ? "ellipsis" : "",
    // color: theme.palette.primary.contrastText,
  }));

  const { palette } = useTheme();
  const textError = palette.error.main;
  const Paragraph = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
  }) => {
    return (
      <StyledBox
        textTransformStyle={textTransform}
        ellipsis={ellipsis}
        className={clsx({
          [className || ""]: true,
        })}
        component="p"
        mb={0}
        mt={0}
        fontSize="14px"
        {...props}
      >
        {children}
      </StyledBox>
    );
  };

  const handleLogin = async () => {
    try {
      setIsLoginDisabled(true);
      var log = await signInWithEmailAndPassword(username, password)
        .then((e) => {
          console.log(e);
          setIsLoginDisabled(false);
          addCount(e);
          history.push("/admin");
        })
        .catch((e) => {
          console.log("error");
          setIsLoginDisabled(false);
        });
      // addCount(log);
      // history.push("/admin");
    } catch (e) {
      console.log(e);
      setMessage(e.message);
      setIsLoginDisabled(false);
    }
  };

  const addCount = async (log) => {
    if (log.user.uid != null) {
      var date = new Date();
      var day = date.getDate();
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      var month = date.getMonth();
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      var hour = date.getHours();
      if (hour.toString().length == 1) {
        hour = "0" + hour;
      }
      var minute = date.getMinutes();
      if (minute.toString().length == 1) {
        minute = "0" + minute;
      }
      var second = date.getSeconds();
      if (second.toString().length == 1) {
        second = "0" + second;
      }
      var newDate =
        day +
        "-" +
        month +
        "-" +
        date.getFullYear() +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      var loginCount = 0;
      try {
        var loginCountCheck = await firebase
          .firestore()
          .collection("userData")
          .doc(log.user.uid)
          .get();
        loginCount = loginCountCheck.data().loginCount + 1;
      } catch (e) {
        console.log(e);
        loginCount = loginCount + 1;
      }
      await firebase
        .firestore()
        .collection("userData")
        .doc(log.user.uid)
        .update({ loginCount: loginCount, lastLogin: newDate })
        .then((val) => {
          console.log(val);
        })
        .catch(async (e) => {
          console.log(e);
          await firebase
            .firestore()
            .collection("userData")
            .doc(log.user.uid)
            .set({ loginCount: loginCount, lastLogin: newDate })
            .then((val) => {
              console.log(val);
            });
        });
    }
  };

  return (
    <section className="join">
      <img className="game-logo" src={AOLogo} alt="Logo" />
      <div className="intro margin-s">
        <h1 className="intro-title">Amazing Observers</h1>
        <p className="intro-content">
          Are you good at observing people? Then, it's time to prove,your skills
          to claim the 'Amazing Observer' title.
        </p>
      </div>
      <div className="input-container">
        <h2>Login</h2>
        <div className="inputs-group inputs-join">
          <label htmlFor="name">Enter Email ID</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Email ID"
          />
        </div>
        <div className="inputs-group inputs-join" id="room-code-input">
          <label htmlFor="room-code">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
          />
        </div>
        <button
          onClick={handleLogin}
          disabled={isLoginDisabled}
          className="inputs-btn"
        >
          Login
          {isLoginDisabled && (
            <>
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </>
          )}
        </button>
        {message && <Paragraph sx={{ color: textError }}>{message}</Paragraph>}
      </div>
    </section>
  );
}

export default LoginPage;
